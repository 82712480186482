


import React from "react"
import { v4 as uuidv4 } from "uuid"
import SaleFlag from "../../../templates/ProductUI/SaleFlag"
import { SlideBody, SlideHeader } from "../../UI/Slider/Slide"
import { largeSlideCls } from "../../UI/Slider/Slide.module.scss"
import { ExternalSlideBody } from "../../UI/Slider/Slide"
const Card = ({ product }, isExternal = false ) => {

  return (
    <>
      {product.onSale && <SaleFlag />}
      <div  key={uuidv4()} className={largeSlideCls}>        
        {isExternal ? <ExternalSlideBody product={product} />  : product.featuredImage && <SlideBody image={product.featuredImage} />}
        {product && <SlideHeader product={product} />}
      </div>
    </>
  )
}

export default Card

