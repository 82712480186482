// extracted by mini-css-extract-plugin
export var bgGrey100 = "ExternalProductsCarousel-module--bg-grey-100--ca68c";
export var bgGrey150 = "ExternalProductsCarousel-module--bg-grey-150--c784c";
export var bgGrey200 = "ExternalProductsCarousel-module--bg-grey-200--c6316";
export var bgGrey300 = "ExternalProductsCarousel-module--bg-grey-300--0c6ef";
export var bgGrey400 = "ExternalProductsCarousel-module--bg-grey-400--51a45";
export var bgGrey500 = "ExternalProductsCarousel-module--bg-grey-500--acb66";
export var bgGrey600 = "ExternalProductsCarousel-module--bg-grey-600--6c233";
export var bgGrey700 = "ExternalProductsCarousel-module--bg-grey-700--2eb2c";
export var bgGrey800 = "ExternalProductsCarousel-module--bg-grey-800--2ac44";
export var bgGrey900 = "ExternalProductsCarousel-module--bg-grey-900--90cc5";
export var innerWrapper = "ExternalProductsCarousel-module--innerWrapper--8481b";
export var sectionCls = "ExternalProductsCarousel-module--sectionCls--977b7";
export var textGrey100 = "ExternalProductsCarousel-module--text-grey-100--49d3f";
export var textGrey150 = "ExternalProductsCarousel-module--text-grey-150--2f521";
export var textGrey200 = "ExternalProductsCarousel-module--text-grey-200--d3019";
export var textGrey300 = "ExternalProductsCarousel-module--text-grey-300--a9e72";
export var textGrey400 = "ExternalProductsCarousel-module--text-grey-400--13030";
export var textGrey500 = "ExternalProductsCarousel-module--text-grey-500--5d6bb";
export var textGrey600 = "ExternalProductsCarousel-module--text-grey-600--ee70e";
export var textGrey700 = "ExternalProductsCarousel-module--text-grey-700--41546";
export var textGrey800 = "ExternalProductsCarousel-module--text-grey-800--a82b8";
export var textGrey900 = "ExternalProductsCarousel-module--text-grey-900--b8906";
export var wrapper = "ExternalProductsCarousel-module--wrapper--f2978";