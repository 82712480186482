import { graphql } from "gatsby"
import React from "react"

import ExternalProductsCarousel from "./ExternalProductsCarousel"

export const fragment = graphql`
  fragment ExternalProductsCarouselFragment on WpPage_Flexlayouts_FlexibleLayouts_ExternalProductsCarousel {
    sectionHeading
    button {
      url
      title
      target
    }
    config {
      padding {
        top {
          topMobile
          topTablet
          topDesktop
        }
        bottom {
          bottomMobile
          bottomTablet
          bottomDesktop
        }
      }
    }
  }
`

export const ACFExternalProductsCarousel = ({
  sectionHeading,
  button,
  config,
}) => {

  return (
    <ExternalProductsCarousel
      sectionHeading={sectionHeading}
      button={button}
      config={config}
    />
  )
}
