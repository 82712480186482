import axios from 'axios';
import React, { Fragment, useRef, useEffect, useState } from "react"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Slider from "react-slick"
import { v4 as uuidv4 } from "uuid"

import { infiniteScrollSettings } from '../../UI/Slider/Slide';
import { Section, Container, SectionHeading, LinkButton } from "../../UI/Common"

import Card from "./Card"
import { PrevButton, NextButton } from '../../UI/Slider/SliderArrows';

import * as style from "./ExternalProductsCarousel.module.scss"

const ExternalProductsCarousel = ({ sectionHeading, button, config }) => {
  const { padding } = config || {}
  const [slide, setSlide] = useState(0)
  const [accessories, setAccessories] = useState({
    isLoading: true,
    isSuccess: false,
    products: [],
    sliderIndex: 0,
    sliderEnd: false
  })

  useEffect(()=> {
    const config = {
      baseURL: process.env.ACCESSORIES_SVC_BACKEND_BASE_URL,
    }

    // TODO: axios.get(`/api/v1/related-products`, config)
    axios.get(`/api/v1/products`, config)
      .then(response => {
        const data = response.data;
        setAccessories({
          ...accessories,
          isLoading: false,
          isSuccess: true,
          products: [...data.data],
        })
      })
      .catch(error => {
        console.error(error)
        setAccessories({
          ...accessories,
          isLoading: false,
          isSuccess: false,
          products: [],
        })
      })
  },[])

  const slider = useRef(null)

 

  return (
    <Section className={style.sectionCls} padding={padding}>
      <>
        <Container fluid="lg">
        <div className="d-flex flex-column flex-md-row justify-content-md-between mb-3 mb-lg-5">
          <SectionHeading text={sectionHeading} />
          {button && (
            <LinkButton
              link={button?.url}
              title={button?.title}
              target={button?.target}
              variant="outlined"
            />
          )}
        </div>

        </Container>
        <div className={style.wrapper}>
          <div className={style.innerWrapper}>
          <>
              <div>
                <PrevButton ref={slider} show={[slide, setSlide]} />
              </div>
              <div>
                <NextButton ref={slider} show={[slide, setSlide]} />
              </div>
            </>

            <Slider ref={slider} {...infiniteScrollSettings}>
              {accessories && accessories.products.map(product => {
                return (
                  <Fragment key={uuidv4()}>
                    <Card product={product} isExternal={true} />
                  </Fragment>
                )
              })}
            </Slider>

          </div>
        </div>
      </>
    </Section>
  )
}

export default ExternalProductsCarousel
